import "./App.css"
import React, { useCallback } from "react"
import { Button, Card, Form, Input } from "antd"
import { AarcFundKitModal } from "@aarc-xyz/fundkit-web-sdk"
import { Image } from "antd"
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons"
import DepositBanner from "./assets/deposit-banner.svg"
import ContractBanner from "./assets/contract-banner.svg"

const config = {
    appName: "Dapp Name",
    module: {
        exchange: {
            enabled: true,
            env: "prod",
        },
        onRamp: {
            enabled: true,
            onRampConfig: {
                mode: "deposit",
                customerId: "323232323",
                exchangeScreenTitle: "Deposit funds in your wallet",
                networks: ["ethereum", "polygon"],
                defaultNetwork: "polygon",
            },
        },
        bridgeAndSwap: {
            enabled: true,
            fetchOnlyDestinationBalance: false,
            routeType: "Value",
        },
    },
    destination: {
        walletAddress: "0xeDa8Dec60B6C2055B61939dDA41E9173Bab372b2",
    },
    apiKeys: {
        aarcSDK: "b776f4d7-5df5-4e8c-a128-058bbe3eaace", // Prod
    },
    events: {
        onTransactionSuccess: (data) => {
            console.log("onTransactionSuccess", data)
        },
        onTransactionError: (data) => {
            console.log("onTransactionError", data)
        },
        onWidgetClose: () => {
            console.log("onWidgetClose")
        },
        onWidgetOpen: () => {
            console.log("onWidgetOpen")
        },
    },
    origin: window.location.origin,
}

const AarcModal = new AarcFundKitModal(config)

// initialize the modal listeners
AarcModal.init()

export function OpenModal() {
    const [address, setAddress] = React.useState(
        AarcModal?.config.destination.walletAddress
    )
    const handleSubmit = useCallback(() => {
        AarcModal.openModal()
    }, [])

    const generateContractPayload = async () => {
        AarcModal?.updateDestinationContract({
            contractAddress: "0x94De497a5E88Da7bc522a8203100f99Dd6e6171e",
            contractGasLimit: "2000000",
            contractPayload:
                "0xc6c3bbe6000000000000000000000000a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48000000000000000000000000eda8dec60b6c2055b61939dda41e9173bab372b200000000000000000000000000000000000000000000000000000000000f4240",
            contractName: "Aave",
        })
        return
    }

    return (
        <div className="flex flex-col gap-5 items-center justify-center">
            <Card title="Transfer Balance" style={{ width: "500px" }}>
                <div className="flex flex-col gap-5 items-center justify-center">
                    <Form>
                        <Form.Item>
                            <div className="text-xs font-bold mb-1">
                                Recipient wallet address:
                            </div>
                            <Input
                        
                                placeholder="Enter address"
                                title="Enter address"
                                variant="filled"
                                value={address}
                                onChange={(e) => {
                                    setAddress(e.target.value)
                                    AarcModal?.updateDestinationWalletAddress(
                                        e.target.value
                                    )
                                    console.log("AarcModal", AarcModal)
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Image
                                onClick={() => {
                                    AarcModal?.updateDestinationContract(
                                        undefined
                                    )
                                    handleSubmit()
                                }}
                                className="cursor-pointer hover:opacity-70"
                                preview={false}
                                src={DepositBanner}
                                alt="logo"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Image
                                onClick={async () => {
                                    try {
                                        await generateContractPayload()
                                        handleSubmit()
                                    } catch (e) {
                                        console.log(e)
                                    }
                                }}
                                className="cursor-pointer hover:opacity-70"
                                preview={false}
                                src={ContractBanner}
                                alt="logo"
                            />
                        </Form.Item>
                      
                    </Form>
                </div>
            </Card>
        </div>
    )
}

function App() {
    return (
        <div
            className="App flex-col"
            style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className="aarc_main_container">
                <OpenModal />
            </div>
        </div>
    )
}

export default App
